.support_main_container {
  max-width: var(--max-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 5rem 0 5rem 0;
  position: relative;
  box-sizing: border-box;
}

.support_container {
  flex-direction: row;
  display: flex;
  height: 450px;
}

.support_desc_container {
  width: 40%;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #28c7b3;
  border-radius: 2rem 0rem 0rem 2rem;
}

.support_desc_container h1 {
  font-size: clamp(1rem, 6vw, 2.5rem);
}

.support_input_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid #28c7b3;
  border-radius: 0rem 2rem 2rem 0rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2.5rem 3rem rgba(0, 0, 0, 0.5);
}

.input_container {
  color: #262626;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.custom_input {
  width: 100%;
  padding: 0.8rem 1rem;
  padding: 10px 0px 10px 10px;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
  border: 1px solid transparent;
  color: white;
}

.custom_input::placeholder {
  color: #b9b9b9;
  opacity: 1; /* Firefox */
}

.custom_input:focus {
  border-bottom: 2px solid white;
}

.dropdown {
  padding: 10px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 16px;
  margin-right: 10px;
}

.input_lable {
  white-space: nowrap;
  color: white;
  font-size: 16px;
  width: 40%;
  display: flex;
  align-items: flex-end;
}

.submit_btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  font-weight: bold;
  background-color: var(--theme-color);
  color: white;
  align-self: flex-end;
}

.issue_select {
  order: none;
  border-radius: 0.5rem;
  padding: 0.8rem 1rem;
  appearance: none;
  outline: none;
  padding-right: 2rem;
  color: white;
  background: url("../../../public/img/select.svg") calc(100% - 0.5rem) 50%
    no-repeat var(--theme-color);
}

.issue_select_container {
  width: 100%;
}

.details_container {
  width: 100%;
  height: 150px;
  padding: 0.8rem 1rem;
  padding: 10px 0px 10px 10px;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
  border: 1px solid transparent;
  color: white;
  resize: none;
}

.details_container::placeholder {
  color: #b9b9b9;
  opacity: 1; /* Firefox */
}

.details_container:focus {
  border-bottom: 2px solid white;
}

.input_lable b {
  font-weight: normal;
  color: red;
  display: inline-block;
  margin-left: 0.2rem;
}

.shake {
  animation: shake 0.05s;
}

@media (max-width: 800px) {
  .support_container {
    flex-direction: column;
    height: 100%;
  }

  .support_desc_container {
    margin-bottom: 30px;
    width: auto;
    background-color: transparent;
    border: 1px solid transparent;
  }

  .support_input_container {
    width: auto;
    border: 1px solid transparent;
    padding-right: 0.5rem;
    border-radius: 2rem;
    padding: 30px 30px;
  }

  .details_container {
    width: 100%;
    height: 150px;
    padding: 0.8rem 1rem;
    padding: 10px 0px 10px 10px;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.2);
    outline: none;
    border: 1px solid transparent;
    color: white;
    resize: none;
  }
}
