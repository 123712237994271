.spinner-container {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #28c7b3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.error {
  padding-inline: 20px;
  padding: 5px 20px 5px 20px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  color: #f3f3f3;
  font-size: 18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
