@import url("https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@300;500&display=swap");
:root {
  --title-font: "Anton", sans-serif;
  --main-font: "Roboto", sans-serif;
  --theme-color: #28c7b3;
  --max-width: 1200px;
}

html {
  font-family: var(--main-font);
  font-weight: 300;
  font-size: 16px;
  background-color: #262626;
  color: white;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
  line-height: 1.3;
  font-weight: normal;
}

html,
body {
  min-height: 100vh;
}

body {
  background-image: url("../public/img/bg_monotone.jpg");
  background-position: top center;
  background-size: auto 1120px;
  background-repeat: repeat;
  -webkit-animation: loopBG 40s linear infinite;
  animation: loopBG 40s linear infinite;
}

main {
  padding: 0 2rem;
}

footer {
  display: flex;
  align-items: center;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 5rem;
  font-size: 0.9rem;
  position: relative;
  z-index: 1;
}
footer img {
  margin-right: 1rem;
  width: 2.5rem;
}

.pop_download,
.pop_form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  padding: 2rem;
  opacity: 0;
  overflow: auto;
  transition: all 0.15s linear 0.25s;
  pointer-events: none;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  justify-content: center;
  align-items: center;
  display: flex;
}
.pop_download .inner_pop_download,
.pop_download .inner_pop_form,
.pop_form .inner_pop_download,
.pop_form .inner_pop_form {
  margin: auto;
  background-color: white;
  border-radius: 1rem;
  opacity: 0;
  transform: translateY(1.5rem);
  transition: all 0.15s linear 0s;
  box-shadow: 0 2.5rem 3rem rgba(0, 0, 0, 0.5);
  padding-bottom: 1.5rem;
  width: 50%;
}
.pop_download .inner_pop_download .links,
.pop_download .inner_pop_form .links,
.pop_form .inner_pop_download .links,
.pop_form .inner_pop_form .links {
  padding: 1rem 2rem 2rem 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.33);
}
.pop_download .inner_pop_download .links .each_link,
.pop_download .inner_pop_form .links .each_link,
.pop_form .inner_pop_download .links .each_link,
.pop_form .inner_pop_form .links .each_link {
  padding: 1rem 1.5rem;
  background-color: white;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  transition: all 0.15s linear;
}
.pop_download .inner_pop_download .links .each_link:active,
.pop_download .inner_pop_form .links .each_link:active,
.pop_form .inner_pop_download .links .each_link:active,
.pop_form .inner_pop_form .links .each_link:active {
  transform: translateY(1px);
}
.pop_download .inner_pop_download .links .each_link:hover,
.pop_download .inner_pop_form .links .each_link:hover,
.pop_form .inner_pop_download .links .each_link:hover,
.pop_form .inner_pop_form .links .each_link:hover {
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
}
.pop_download .inner_pop_download .links .each_link:hover .des,
.pop_download .inner_pop_form .links .each_link:hover .des,
.pop_form .inner_pop_download .links .each_link:hover .des,
.pop_form .inner_pop_form .links .each_link:hover .des {
  color: #20ac97;
}
.pop_download .inner_pop_download .links .each_link:hover .dl_icon,
.pop_download .inner_pop_form .links .each_link:hover .dl_icon,
.pop_form .inner_pop_download .links .each_link:hover .dl_icon,
.pop_form .inner_pop_form .links .each_link:hover .dl_icon {
  background-color: #20ac97;
}
.pop_download .inner_pop_download .links .each_link:hover .dl_icon .anime,
.pop_download .inner_pop_form .links .each_link:hover .dl_icon .anime,
.pop_form .inner_pop_download .links .each_link:hover .dl_icon .anime,
.pop_form .inner_pop_form .links .each_link:hover .dl_icon .anime {
  transform: translate(-50%, -65%);
}
.pop_download .inner_pop_download .links .each_link:last-of-type,
.pop_download .inner_pop_form .links .each_link:last-of-type,
.pop_form .inner_pop_download .links .each_link:last-of-type,
.pop_form .inner_pop_form .links .each_link:last-of-type {
  margin-bottom: 0;
}
.pop_download .inner_pop_download .links .each_link .des,
.pop_download .inner_pop_form .links .each_link .des,
.pop_form .inner_pop_download .links .each_link .des,
.pop_form .inner_pop_form .links .each_link .des {
  display: flex;
  flex-direction: column;
  color: var(--theme-color);
  font-size: 1.15rem;
  transition: all 0.15s linear;
}
.pop_download .inner_pop_download .links .each_link .des small,
.pop_download .inner_pop_form .links .each_link .des small,
.pop_form .inner_pop_download .links .each_link .des small,
.pop_form .inner_pop_form .links .each_link .des small {
  color: #262626;
  margin-top: 0.25rem;
  opacity: 0.33;
}
.pop_download .inner_pop_download .links .each_link .dl_icon,
.pop_download .inner_pop_form .links .each_link .dl_icon,
.pop_form .inner_pop_download .links .each_link .dl_icon,
.pop_form .inner_pop_form .links .each_link .dl_icon {
  margin-left: auto;
  padding: 0.5rem;
  background-color: var(--theme-color);
  border-radius: 2rem;
  transition: all 0.15s linear;
  position: relative;
}
.pop_download .inner_pop_download .links .each_link .dl_icon .anime,
.pop_download .inner_pop_form .links .each_link .dl_icon .anime,
.pop_form .inner_pop_download .links .each_link .dl_icon .anime,
.pop_form .inner_pop_form .links .each_link .dl_icon .anime {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.pop_download .inner_pop_download .pop_download_top,
.pop_download .inner_pop_form .pop_download_top,
.pop_form .inner_pop_download .pop_download_top,
.pop_form .inner_pop_form .pop_download_top {
  display: flex;
  padding: 1rem 2rem;
  align-items: center;
}
.pop_download .inner_pop_download .pop_download_top h2,
.pop_download .inner_pop_form .pop_download_top h2,
.pop_form .inner_pop_download .pop_download_top h2,
.pop_form .inner_pop_form .pop_download_top h2 {
  font-size: 1.25rem;
  margin-right: auto;
  font-weight: bold;
  font-family: var(--main-font);
  color: var(--theme-color);
}
.pop_download .inner_pop_download .pop_download_top .close_download,
.pop_download .inner_pop_download .pop_download_top .close_form,
.pop_download .inner_pop_form .pop_download_top .close_download,
.pop_download .inner_pop_form .pop_download_top .close_form,
.pop_form .inner_pop_download .pop_download_top .close_download,
.pop_form .inner_pop_download .pop_download_top .close_form,
.pop_form .inner_pop_form .pop_download_top .close_download,
.pop_form .inner_pop_form .pop_download_top .close_form {
  border-radius: 10rem;
  background-color: #ebebeb;
  cursor: pointer;
  transition: all 0.15s linear;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.pop_download .inner_pop_download .pop_download_top .close_download img,
.pop_download .inner_pop_download .pop_download_top .close_form img,
.pop_download .inner_pop_form .pop_download_top .close_download img,
.pop_download .inner_pop_form .pop_download_top .close_form img,
.pop_form .inner_pop_download .pop_download_top .close_download img,
.pop_form .inner_pop_download .pop_download_top .close_form img,
.pop_form .inner_pop_form .pop_download_top .close_download img,
.pop_form .inner_pop_form .pop_download_top .close_form img {
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.pop_download .inner_pop_download .pop_download_top .close_download:hover,
.pop_download .inner_pop_download .pop_download_top .close_form:hover,
.pop_download .inner_pop_form .pop_download_top .close_download:hover,
.pop_download .inner_pop_form .pop_download_top .close_form:hover,
.pop_form .inner_pop_download .pop_download_top .close_download:hover,
.pop_form .inner_pop_download .pop_download_top .close_form:hover,
.pop_form .inner_pop_form .pop_download_top .close_download:hover,
.pop_form .inner_pop_form .pop_download_top .close_form:hover {
  opacity: 0.75;
}
.pop_download .inner_pop_download .pop_download_top .close_download:hover img,
.pop_download .inner_pop_download .pop_download_top .close_form:hover img,
.pop_download .inner_pop_form .pop_download_top .close_download:hover img,
.pop_download .inner_pop_form .pop_download_top .close_form:hover img,
.pop_form .inner_pop_download .pop_download_top .close_download:hover img,
.pop_form .inner_pop_download .pop_download_top .close_form:hover img,
.pop_form .inner_pop_form .pop_download_top .close_download:hover img,
.pop_form .inner_pop_form .pop_download_top .close_form:hover img {
  transform: rotate(180deg);
}
.pop_download .inner_pop_download .pop_download_top .close_download img,
.pop_download .inner_pop_download .pop_download_top .close_form img,
.pop_download .inner_pop_form .pop_download_top .close_download img,
.pop_download .inner_pop_form .pop_download_top .close_form img,
.pop_form .inner_pop_download .pop_download_top .close_download img,
.pop_form .inner_pop_download .pop_download_top .close_form img,
.pop_form .inner_pop_form .pop_download_top .close_download img,
.pop_form .inner_pop_form .pop_download_top .close_form img {
  width: 1.75rem;
}
.pop_download .inner_pop_form,
.pop_form .inner_pop_form {
  padding-bottom: 0;
}
.pop_download.showed,
.pop_form.showed {
  pointer-events: auto;
  opacity: 1;
  transition: all 0.15s linear;
  box-sizing: border-box;
}
.pop_download.showed .inner_pop_download,
.pop_download.showed .inner_pop_form,
.pop_form.showed .inner_pop_download,
.pop_form.showed .inner_pop_form {
  opacity: 1;
  transform: translateY(0rem);
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0.25s;
}

.pop_form .inner_pop_form .pop_download_top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pop_download {
  position: fixed;
  top: 0;
  left: 0;
  width: -webkit-fill-available;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  padding: 2rem;
  opacity: 0;
  overflow: auto;
  transition: all 0.15s linear 0.25s;
  pointer-events: none;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.pop_download .inner_pop_download {
  max-width: 640px;
  width: 100%;
  margin: auto;
  margin-bottom: 200px;
  background-color: white;
  border-radius: 1rem;
  opacity: 0;
  transform: translateY(1.5rem);
  transition: all 0.15s linear 0s;
  overflow: hidden;
  box-shadow: 0 2.5rem 3rem rgba(0, 0, 0, 0.5);
  padding-bottom: 1.5rem;
}

.pop_download .inner_pop_download .links {
  padding: 1rem 2rem 2rem 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.33);
}

.pop_download .inner_pop_download .links .each_link {
  padding: 1rem 1.5rem;
  background-color: white;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  transition: all 0.15s linear;
}
.pop_download .inner_pop_download .links .each_link:active {
  transform: translateY(1px);
}
.pop_download .inner_pop_download .links .each_link:hover {
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
}
.pop_download .inner_pop_download .links .each_link:hover .des {
  color: #20ac97;
}
.pop_download .inner_pop_download .links .each_link:hover .dl_icon {
  background-color: #20ac97;
}
.pop_download .inner_pop_download .links .each_link:last-of-type {
  margin-bottom: 0;
}
.pop_download .inner_pop_download .links .each_link .des {
  display: flex;
  flex-direction: column;
  color: var(--theme-color);
  font-size: 1.15rem;
  transition: all 0.15s linear;
}
.pop_download .inner_pop_download .links .each_link .des small {
  color: #262626;
  margin-top: 0.25rem;
  opacity: 0.33;
}
.pop_download .inner_pop_download .links .each_link .dl_icon {
  margin-left: auto;
  padding: 0.5rem;
  background-color: var(--theme-color);
  border-radius: 2rem;
  transition: all 0.15s linear;
}
.pop_download .inner_pop_download .pop_download_top {
  display: flex;
  padding: 1rem 2rem;
  align-items: center;
}
.pop_download .inner_pop_download .pop_download_top h2 {
  font-size: 1.4rem;
  margin-right: auto;
  font-weight: bold;
  font-family: var(--main-font);
  color: var(--theme-color);
}
.pop_download .inner_pop_download .pop_download_top .close_download {
  padding: 0.15rem;
  border-radius: 10rem;
  background-color: #ebebeb;
  cursor: pointer;
  transition: all 0.15s linear;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.pop_download .inner_pop_download .pop_download_top .close_download:hover {
  opacity: 0.75;
}
.pop_download .inner_pop_download .pop_download_top .close_download img {
  width: 1.75rem;
}
.pop_download.showed {
  pointer-events: auto;
  opacity: 1;
  transition: all 0.15s linear;
}
.pop_download.showed .inner_pop_download {
  opacity: 1;
  transform: translateY(0rem);
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0.25s;
}

header {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 0rem;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header button {
  margin-left: auto;
  display: inline-block;
  padding: 0.6rem 1rem;
  border-radius: 10rem;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.15s linear;
  font-size: 0.9rem;
  font-weight: bold;
  border: 1px solid white;
  color: white;
}

header button:hover {
  background-color: white;
  color: var(--theme-color);
}

header img {
  height: clamp(3rem, 5vw, 3.5rem);
}

header a {
  margin-left: auto;
  display: inline-block;
  padding: 0.6rem 1rem;
  border-radius: 10rem;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.15s linear;
  font-size: 0.9rem;
  font-weight: bold;
  border: 1px solid white;
}
header a:hover {
  background-color: white;
  color: var(--theme-color);
}

.form {
  padding: 1rem 4rem;
}

.form .each_fieldset {
  color: #262626;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.form .each_fieldset.__is_remark {
  margin-bottom: 0;
}

.form .each_fieldset.__is_remark div textarea {
  resize: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  transition: height 0.2s ease-in-out;
}

.form .each_fieldset .error_msg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8rem;
  color: red;
  padding: 1rem;
}

.error_msg_2 {
  font-size: 0.8rem;
  color: red;
  position: absolute;
}

.form .each_fieldset.__is_multiple {
  flex-wrap: wrap;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 0.5rem;
}

.form .each_fieldset.__is_multiple .label {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 1rem;
}

.form .each_fieldset.__is_multiple .label small {
  display: block;
  margin-top: 0.15rem;
  opacity: 0.5;
}

.form .each_fieldset .label {
  width: 20%;
  flex-shrink: 0;
}

.form .each_fieldset .multiple_input_wrap .each_multiple_box label b {
  font-weight: normal;
  color: red;
  display: inline-block;
  margin-left: 0.2rem;
}

.form .each_fieldset .label b {
  font-weight: normal;
  color: red;
  display: inline-block;
  margin-left: 0.2rem;
}

.form .each_fieldset .input_wrap {
  flex: 1;
  position: relative;
}

.form .each_fieldset .input_wrap textarea {
  width: 100%;
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  background-color: #ebebeb;
  outline: none;
  resize: none;
  box-sizing: border-box;
}

.form .each_fieldset .input_wrap.__is_contact {
  display: flex;
  padding: 0;
}

.form .each_fieldset .input_wrap.__is_contact input {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
}

.form .each_fieldset .input_wrap.__is_contact select {
  border: none;
  border-radius: 0.5rem 0 0 0.5rem;
  padding: 0.8rem 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  padding-right: 2rem;
  color: white;
  background: url("../public/img/select.svg") calc(100% - 0.5rem) 50% no-repeat
    var(--theme-color);
}

.form .each_fieldset .input_wrap.__is_contact input {
  flex: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form .each_fieldset .input_wrap {
  width: 100%;
  padding: 0.8rem 1rem;
  padding: 10px 0px 10px 0;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  background-color: #ebebeb;
  outline: none;
}

.form .each_fieldset .input_wrap input {
  padding: 0 10px;
  width: 70%;
  outline: none;
  border: none;
  background-color: #ebebeb;
}

.form .each_fieldset .each_container {
  background-color: #ebebeb;
}

.form .multiple_input_wrap {
  display: flex;
  flex-wrap: wrap;
  /* width: 100%; */
  justify-content: space-between;
}

.form .multiple_input_wrap .each_multiple_box {
  width: calc(50% - 0.5rem);
  padding: 0.75rem 0;
}

.form .multiple_input_wrap .each_multiple_box .expanded_textbox {
  margin-top: 0.75rem;
  border-radius: 0.5rem;
}

.form .multiple_input_wrap .each_multiple_box .expanded_textbox textarea {
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  resize: none;
  outline: none;
  width: 100%;
  background-color: #ebebeb;
  transition: max-height 0.3s ease-in-out;
}

.form
  .multiple_input_wrap
  .each_multiple_box
  .expanded_textbox
  textarea::-moz-placeholder {
  color: #b9b9b9;
  opacity: 1; /* Firefox */
}

.form
  .multiple_input_wrap
  .each_multiple_box
  .expanded_textbox
  textarea:-ms-input-placeholder {
  color: #b9b9b9;
  opacity: 1; /* Firefox */
}
.form
  .multiple_input_wrap
  .each_multiple_box
  .expanded_textbox
  textarea::placeholder {
  color: #b9b9b9;
  opacity: 1; /* Firefox */
}

.form
  .multiple_input_wrap
  .each_multiple_box
  .expanded_textbox
  textarea::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #b9b9b9;
}

.form .multiple_input_wrap .each_multiple_box input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.00001;
  width: 1px;
  height: 1px;
  z-index: -10;
}

.form .multiple_input_wrap .each_multiple_box input:checked ~ label:after {
  background: url("../public/img/check.svg") 50% 50% no-repeat transparent;
  width: 24px;
  height: 24px;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.form .multiple_input_wrap .each_multiple_box input:checked ~ label::before {
  background-color: #dedede;
}

.form .multiple_input_wrap .each_multiple_box label {
  padding-left: 2.5rem;
  position: relative;
  display: block;
}

.form .multiple_input_wrap .each_multiple_box label:before {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background-color: #ebebeb;
}

.form .multiple_input_wrap .each_multiple_box.__full_width {
  width: 100%;
}

.inner_how_to_install {
  display: flex;
  flex-direction: column;
  background: white;
  color: #262626;
  padding: 3rem;
  border-radius: 0 0 2rem 2rem;
  display: none;
}
.inner_how_to_install h3 {
  margin-bottom: 1rem;
  font-family: var(--main-font);
  font-weight: bold;
  color: var(--theme-color);
  display: flex;
  align-items: center;
}
.inner_how_to_install h3 img {
  margin-right: 0.5rem;
  width: 1.75rem;
}

.submit_btn_wrap {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submit_btn_wrap b {
  color: red;
  font-weight: normal;
}

.submit_btn_wrap small {
  color: #9e9e9e;
}

.submit_btn_wrap button[type="submit"],
.submit_btn_wrap button[type="button"] {
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  font-weight: bold;
  background-color: var(--theme-color);
  color: white;
  transition: all 0.15s linear;
}
.submit_btn_wrap button[type="submit"]:hover,
.submit_btn_wrap button[type="button"]:hover {
  background-color: #20ac97;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
}
.submit_btn_wrap button[type="button"] {
  background-color: rgba(0, 0, 0, 0.075);
  color: #505050;
  padding: 1rem 1.25rem;
}
.submit_btn_wrap button[type="button"]:hover {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
}

.submit_btn_wrap button:hover {
  background-color: #20ac97;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
}

.steps {
  display: flex;
  flex-direction: column;
}
.steps .each_step {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
  position: relative;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.steps .each_step .my_files {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  line-height: 1.3;
  order: 3;
  color: #262626;
}
.steps .each_step .my_files .left {
  flex: 1;
}
.steps .each_step .my_files .right {
  border-radius: 0.75rem;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  margin-left: 1.5rem;
  order: 2;
}
.steps .each_step .my_files .right img {
  width: clamp(9.5rem, 14vw, 12.5rem);
}
.steps .each_step:nth-of-type(2):hover .step_img:after {
  box-shadow: inset 0 0 0 3px var(--theme-color);
  opacity: 1;
}
.steps .each_step .step2btn {
  cursor: pointer;
  color: var(--theme-color);
  background-color: var(--theme-color);
  display: inline-flex;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  transition: all 0.15s linear;
}

.steps .each_step a:hover {
  background-color: #20ac97;
}
.steps .each_step a:after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.steps .each_step .step_img {
  border-radius: 0.75rem;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  margin-left: 1.5rem;
  order: 2;
}
.steps .each_step .step_img img {
  width: clamp(9.5rem, 14vw, 12.5rem);
}
.steps .each_step .step_img:after {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 0 1px black;
  opacity: 0.1;
  content: "";
  transition: all 0.15s linear;
}
.steps .each_step:last-of-type {
  margin-bottom: 0;
}
.steps .each_step .step_des {
  order: 1;
  line-height: 1.3;
  color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.steps .each_step .step_des small {
  margin-bottom: 0.25rem;
  color: var(--theme-color);
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
}
.steps .each_step .step_des small b {
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 0.25rem 0.45rem;
  border-radius: 2rem;
  font-weight: 300;
  margin-left: 0.5rem;
  line-height: 1;
}
.hidden_download_links {
  display: flex;
  position: relative;
}

.hidden_download_links .inner_hidden_download_links {
  padding: 1rem 2rem;
  position: relative;
  font-size: 0.9rem;
  background-color: #ebebeb;
  margin-bottom: 0.5rem;
  width: 100%;
}
.hidden_dl_links_open {
  max-height: 0;
  transition: 0.5s ease-out;
}

.hidden_dl_links_close {
  max-height: 0;
  transition: 0.5s ease-out;
  opacity: 0;
}

.hidden_download_links .inner_hidden_download_links strong {
  color: rgba(0, 0, 0, 0.33);
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 300;
}
.hidden_download_links .inner_hidden_download_links .close_dl_links {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10%);
  background-color: white;
  border-radius: 5rem;
  padding: 0.4rem;
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 0 0 3px rgba(40, 199, 179, 0.5);
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hidden_download_links .inner_hidden_download_links .close_dl_links img {
  width: clamp(1.25rem, 3vw, 1.75rem);
}
.hidden_download_links .inner_hidden_download_links .close_dl_links:hover {
  box-shadow: 0 0 0 3px rgb(40, 199, 179);
}
.hidden_download_links .inner_hidden_download_links .close_dl_links.active {
  opacity: 1;
  transform: translate(-50%, -50%);
}
.hidden_download_links .inner_hidden_download_links div {
  display: flex;
  align-items: center;
  color: var(--theme-color);
  padding: 0.7rem 1rem;
  background-color: white;
  border-radius: 0.5rem;
  transition: all 0.15s linear;
  box-shadow: 0 0 0 0px rgba(40, 199, 179, 0.5);
  margin-bottom: 0.35rem;
}
.hidden_download_links .inner_hidden_download_links div:last-of-type {
  margin-bottom: 0;
}
.hidden_download_links .inner_hidden_download_links div:hover {
  color: white;
  background-color: var(--theme-color);
}
.hidden_download_links .inner_hidden_download_links div:hover .dl_icon {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px white;
}
.hidden_download_links .inner_hidden_download_links div:hover .dl_icon .dl_f {
  transform: translate(-50%, -60%);
}
.hidden_download_links .inner_hidden_download_links div .dl_des {
  font-weight: 600;
  display: flex;
  flex-direction: column;
}
.hidden_download_links .inner_hidden_download_links div .dl_des small {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.33);
  margin-top: 0.15rem;
}
.hidden_download_links .inner_hidden_download_links div .dl_icon {
  position: relative;
  margin-left: auto;
  background-color: var(--theme-color);
  border-radius: 5rem;
  padding: 0.5rem;
  transition: all 0.15s linear;
}
.hidden_download_links .inner_hidden_download_links div .dl_icon img {
  width: 1.35rem;
}
.hidden_download_links .inner_hidden_download_links div .dl_icon .dl_f {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.main_container {
  max-width: var(--max-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 5rem;
  border-radius: 2rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2.5rem 3rem rgba(0, 0, 0, 0.5);
  position: relative;
  box-sizing: border-box;
}
.main_container .dude {
  position: absolute;
  bottom: 0;
  right: -2rem;
  width: clamp(35rem, 70vw, 48rem);
}
.main_container .left_block {
  width: 50%;
  position: relative;
  z-index: 2;
}
.main_container .left_block h1 {
  font-size: clamp(2rem, 6vw, 4.5rem);
}
.main_container .left_block h1 u {
  position: relative;
  text-decoration-thickness: 0.5rem;
  -webkit-text-decoration-color: var(--theme-color);
  text-decoration-color: var(--theme-color);
}
.main_container .left_block_lower {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
}
.main_container .left_block_lower .android_only {
  display: inline-flex;
  align-items: center;
  margin-top: 1rem;
}
.main_container .left_block_lower .android_only img {
  margin-right: 0.5rem;
}
.main_container .left_block_lower .download_btn {
  align-self: flex-start;
  cursor: pointer;
}
.main_container .left_block_lower .download_btn span {
  display: inline-flex;
  align-items: center;
  padding: 1rem 2.25rem 1rem 1.25rem;
  font-family: var(--title-font);
  background-color: var(--theme-color);
  color: white;
  font-size: clamp(1.65rem, 4vw, 2rem);
  border-radius: 0.5rem;
  transition: all 0.15s linear;
}
.main_container .left_block_lower .download_btn:hover span {
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
  background-color: #20ac97;
}
.main_container .left_block_lower .download_btn img {
  margin-right: 0.5rem;
  width: 2.75rem;
}

.shake {
  animation: shake 0.05s;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

@-webkit-keyframes loopBG {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center -1120px;
  }
}

@keyframes loopBG {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center -1120px;
  }
}

@media (max-width: 1200px) {
  :root {
    --max-width: 1000px;
  }
  .steps .each_step .step_img img,
  .steps .each_step .my_files .right img {
    width: clamp(12rem, 17vw, 13rem);
  }
  @-webkit-keyframes loopBG {
    0% {
      background-position: center 0;
    }
    100% {
      background-position: center -910px;
    }
  }
  @keyframes loopBG {
    0% {
      background-position: center 0;
    }
    100% {
      background-position: center -910px;
    }
  }
  html {
    font-size: 15px;
  }
  body {
    background-size: auto 910px;
  }
  header {
    padding: 2rem 0rem;
  }
  footer {
    padding: 1.5rem 3.5rem;
  }
  .main_container .dude {
    width: clamp(30rem, 62vw, 42rem);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3;
  }

  .form .multiple_input_wrap .each_multiple_box {
    width: 100%;
  }
}
@media (max-width: 960px) {
  .form .multiple_input_wrap {
    display: block;
  }

  footer img {
    width: 2.25rem;
  }
}
@media (max-width: 820px) {
  html {
    font-size: 14px;
  }
  .steps .each_step .step_img img,
  .steps .each_step .my_files .right img {
    width: clamp(14rem, 17vw, 15rem);
  }
  .inner_how_to_install .steps .each_step {
    font-size: 0.85rem;
    margin-right: 0.9rem;
  }
  .inner_how_to_install .steps .each_step .step_des {
    margin-top: 0.8rem;
  }
  .inner_how_to_install .steps .each_step .step_img {
    border-radius: 0.7rem;
  }
  .main_container {
    padding: 4rem;
  }
  .main_container .left_block_lower {
    margin-top: 6rem;
  }
  .main_container .left_block {
    width: 80%;
  }
  .main_container .left_block h1 {
    font-size: clamp(3.5rem, 7.5vw, 4.5rem);
  }
  .main_container .dude {
    right: -2rem;
    width: auto;
    height: clamp(28rem, 34vw, 30rem);
  }
}

@media (max-width: 768px) {
  .main_container .left_block_lower .download_btn img {
    width: 2.75rem;
  }
  .main_container .left_block_lower .android_only img {
    width: 1.5rem;
  }
  .inner_how_to_install {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
  }
  .inner_how_to_install .steps {
    flex-direction: column;
  }
  .inner_how_to_install .steps .each_step {
    display: flex;
    width: 100%;
    margin-right: 0;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
  .inner_how_to_install .steps .each_step:last-of-type {
    margin-bottom: 0;
  }
  .inner_how_to_install .steps .each_step .step_des {
    margin-top: 0;
    order: 1;
    flex: 1;
  }
  .inner_how_to_install .steps .each_step .step_img {
    width: 36%;
    margin-left: 1.5rem;
    order: 2;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.45;
  }

  .pop_download .inner_pop_download,
  .pop_download .inner_pop_form,
  .pop_form .inner_pop_download,
  .pop_form .inner_pop_form {
    width: auto;
  }
}
@media (max-width: 560px) {
  @-webkit-keyframes loopBG {
    0% {
      background-position: center 0;
    }
    100% {
      background-position: center -840px;
    }
  }
  @keyframes loopBG {
    0% {
      background-position: center 0;
    }
    100% {
      background-position: center -840px;
    }
  }
  body {
    background-size: auto 840px;
  }
  .inner_how_to_install .steps .each_step {
    margin-bottom: 1rem;
  }
  .main_container .left_block_lower .download_btn span {
    padding-right: 1.725rem;
  }
  .main_container .left_block_lower .download_btn img {
    width: 2.75rem;
  }
  header {
    display: flex;
    justify-content: center;
  }
  header img {
    height: 2.75rem;
  }
  footer {
    display: flex;
    justify-content: center;
  }
  footer img {
    width: 2.2rem;
  }
  .main_container .left_block {
    width: 100%;
  }
  .main_container .dude {
    height: 20rem;
  }
}

@media (max-width: 520px) {
  .inner_how_to_install {
    padding: 2rem 3rem 3rem 3rem;
  }
  .inner_how_to_install .steps .each_step .step_img {
    width: 40%;
    border-radius: 0.5rem;
    margin-left: 1rem;
  }
  header,
  footer {
    padding-left: 0;
    padding-right: 0;
  }
  .main_container {
    padding: 3rem;
  }
}
@media (max-width: 480px) {
  html {
    font-size: 13px;
  }
  @-webkit-keyframes loopBG {
    0% {
      background-position: center 0;
    }
    100% {
      background-position: center -770px;
    }
  }
  @keyframes loopBG {
    0% {
      background-position: center 0;
    }
    100% {
      background-position: center -770px;
    }
  }
  body {
    background-size: auto 770px;
  }
  .steps .each_step .my_files .right,
  .steps .each_step .step_img {
    border-radius: 0.35rem;
  }
  .hidden_download_links .inner_hidden_download_links {
    padding: 0.85rem 1.5rem;
  }
  .hidden_download_links .inner_hidden_download_links a {
    padding: 0.5rem 0.75rem;
  }
  .hidden_download_links .inner_hidden_download_links a .dl_icon img {
    width: 1.2rem;
  }
  .pop_download .inner_pop_download .pop_download_top h2 {
    font-size: 1.1rem;
  }
  .steps .each_step {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .pop_download,
  .pop_form {
    padding: 1.5rem;
  }
  .pop_form .inner_pop_form .pop_download_top {
    padding: 0.75rem 1.5rem;
  }
  .submit_btn_wrap {
    padding: 1rem 1.5rem;
  }
  .form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .pop_download .inner_pop_download .pop_download_top,
  .pop_download .inner_pop_download .links {
    padding: 0.75rem 1.5rem;
  }
  main {
    padding: 0 1.5rem;
  }
  .inner_how_to_install {
    padding: 2rem 2.25rem;
  }
  .main_container .top {
    padding: 2.25rem;
  }
  .main_container .top .left_block h1 {
    font-size: 3rem;
  }
  .main_container .top .dude {
    height: 17.5rem;
    right: -1.5rem;
  }
}

@media (max-width: 420px) {
  @-webkit-keyframes loopBG {
    0% {
      background-position: center 0;
    }
    100% {
      background-position: center -700px;
    }
  }
  @keyframes loopBG {
    0% {
      background-position: center 0;
    }
    100% {
      background-position: center -700px;
    }
  }
  .submit_btn_wrap {
    flex-wrap: wrap;
  }
  .submit_btn_wrap small {
    order: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
  .submit_btn_wrap button[type="button"] {
    order: 2;
    margin-right: auto;
  }
  .submit_btn_wrap button[type="submit"] {
    order: 3;
  }

  .form .each_fieldset {
    flex-direction: column;
    align-items: flex-start;
  }
  .form .each_fieldset .input_wrap {
    width: 100%;
  }
  .form .each_fieldset .label {
    width: 100%;
    margin-bottom: 0.25rem;
  }
  .pop_download .inner_pop_download .pop_download_top,
  .hidden_download_links .inner_hidden_download_links {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .steps .each_step {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .pop_download,
  .pop_form {
    padding: 1rem;
  }
  main {
    padding: 0 1.25rem;
  }
  body {
    background-size: auto 700px;
  }
  .main_container {
    padding: 2rem;
  }
  .main_container .dude {
    height: 16rem;
  }
  footer {
    display: flex;
  }
  footer img {
    width: 1.725rem;
  }
  .main_container .left_block_lower .download_btn img {
    width: 2.25rem;
  }
}
@media (max-width: 360px) {
  .main_container .left_block h1 {
    font-size: 3rem;
  }
}
