.pop_thank_you {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
  padding: 2rem;
  overflow: auto;
  transition: all 0.15s linear 0.25s;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.pop_thank_you .inner_pop_thank_you {
  max-width: 480px;
  padding: 4rem;
  width: 100%;
  margin: auto;
  background-color: white;
  border-radius: 1rem;
  position: relative;
  transform: translateY(1.5rem);
  transition: all 0.15s linear 0s;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 2.5rem 3rem rgba(0, 0, 0, 0.5);
  color: #262626;
}

.pop_thank_you .inner_pop_thank_you .thank_you_message h1 {
  margin-bottom: 0.5rem;
}
.pop_thank_you .inner_pop_thank_you .thank_you_message p {
  font-size: 1.25rem;
}
.pop_thank_you .inner_pop_thank_you .thank_you_message > a {
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  font-weight: bold;
  background-color: var(--theme-color);
  color: white;
  font-size: 1.25rem;
  transition: all 0.15s linear;
}
.pop_thank_you .inner_pop_thank_you .thank_you_message > a:hover {
  background-color: #20ac97;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
}
.pop_thank_you .inner_pop_thank_you .thank_you_message > span.close_thank_you {
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: 0.15rem;
  border-radius: 10rem;
  background-color: #ebebeb;
  cursor: pointer;
  transition: all 0.15s linear;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pop_thank_you
  .inner_pop_thank_you
  .thank_you_message
  > span.close_thank_you
  img {
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.pop_thank_you
  .inner_pop_thank_you
  .thank_you_message
  > span.close_thank_you:hover {
  opacity: 0.75;
}
.pop_thank_you
  .inner_pop_thank_you
  .thank_you_message
  > span.close_thank_you:hover
  img {
  transform: rotate(180deg);
}
.pop_thank_you
  .inner_pop_thank_you
  .thank_you_message
  > span.close_thank_you
  img {
  width: 1.75rem;
}
.pop_thank_you .inner_pop_thank_you .thank_you_message p {
  margin-bottom: 4rem;
}
.pop_thank_you.showed {
  pointer-events: auto;
  opacity: 1;
  transition: all 0.15s linear;
}
.pop_thank_you.showed .inner_pop_thank_you {
  opacity: 1;
  transform: translateY(0rem);
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0.25s;
}
