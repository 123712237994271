.about_us_main_container {
  max-width: var(--max-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 5rem 0 0 0;
  position: relative;
  box-sizing: border-box;
  border-radius: 2rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2.5rem 3rem rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  padding: 5rem;
  border-radius: 2rem;
  margin-top: 50px;
}

.about_us_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about_us_container p {
  text-align: center;
}
